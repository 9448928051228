.meinungen__section {
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background:#232429;
}

.meinungen__container-card {
  background: linear-gradient(45deg, #f00946 0%, #276afb 100%);
  box-shadow: 0 16px 15px rgba(210, 228, 112, 0.8);
  width: 350px;
  height: 550px;
  text-decoration: none;
  border-radius: 60px 60px 0 60px;
}


.meinungen__container-avatar1 {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.meinungen__container-avatar2 {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.meinungen__container-avatar3 {
  vertical-align: middle;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.meinungen__container-card:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: #daa513;
}

.meinungen__container-card {
  margin: 0 24px;
}

.meinungen__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meinungen__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.meinungen__heading {
  color: #1c2237;
  margin-bottom: 24px;
}

.meinungen__container-cardInfo {
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: rgb(34, 27, 27);
}

.meinungen__container-cardInfo h3 {
  margin-bottom: 5px;
  font-size: 24px;
}

.meinungen__container-cardInfo h4 {
  font-size: 40px;
}

.meinungen__container-cardInfo p {
  font-size: 14px;
  margin-bottom: 24px;
}

.meinungen__container-pgbar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meinungen__container-features {
  margin: 16px 0 32px;
  list-style: none;
}

.meinungen__container-features li {
  margin-bottom: 10px;
}

.meinungen__container-features {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 24px 0;
}

blockquote {
  margin-bottom: 3em;
}

.groucho {
  position: relative;
  font-family: 'Sanchez', serif;
  font-size: 1.4em;
  line-height: 1.5em;
  color: white;
}
.groucho footer {
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.8em;
  font-weight: 700;
  color:  #f00946;
  float: right;
}
.groucho footer:before {
  content: '\2015';
  color:#42f593;
}
.groucho:after {
  content: '\201D';
  position: absolute;
  top: 0.28em;
  right: 0;
  font-size: 6em;
  font-style: italic;
  color:#42f593;
  z-index: -1;
}


@media screen and (max-width: 960px) {
  .meinungen__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .meinungen__container-card {
    width: 90%;
    margin: 24px 0;
  }

  .meinungen__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .meinungen__container-card:nth-child(5) {
    /*transform: scale(1);*/
    background:#56f5b8;
    margin: 24px;
    height: auto;
  }

  .meinungen__container-card:hover {
    transform: none;
  }
}