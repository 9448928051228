.skills__section {
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #232429;
}

.skills__container-card {
  background: linear-gradient(45deg, #5b6777 0%, #16181b 100%);
  box-shadow: 0 6px 20px rgba(240, 9, 70, 0.9);
  width: 350px;
  height: 550px;
  text-decoration: none;
  border-radius: 10px;
}

.skills__container-card:nth-child(5) {
  /*transform: scale(1.05);*/
  background: linear-gradient(170deg, #56f5b8 55%, rgba(240, 9, 70, 0.5) 45%);
  box-shadow: 0 6px 20px rgba(91,103,119, 0.9);
  border-radius: 10px;
}

.skills__container-card:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: #daa513;
}

.skills__container-card {
  margin: 0 24px;
}

.skills__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.skills__heading {
  padding-left: 30px;
  color:#f00946;
  margin-bottom: 24px;
  font-size: 2.5em;
  opacity: 0.6;
}

.skills__container-cardInfo {
  display: flex;
  flex-direction: column;
  height: 450px;
  padding: 24px;
  align-items: center;
  color: rgb(34, 27, 27);
}

.skills__container-cardInfo h3 {
  margin-bottom: 5px;
  font-size: 24px;
  color: lightslategray;
  opacity: 0.6;
}

.skills__container-cardInfo h4 {
  font-size: 40px;
  color: lightslategray;
  opacity: 0.6;
}

.skills__container-cardInfo p {
  font-size: 14px;
  margin-bottom: 24px;
  color: grey;
  opacity: 0.8;
}

.skills__container-pgbar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills__container-features {
  margin: 16px 0 32px;
  list-style: none;
  color: lightslategray;
  opacity: 0.8;
}

.skills__container-features li {
  margin-bottom: 10px;
}

.skills__container-features {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 24px 0;
  display: flex;
  font-size: 2em;
}

@media screen and (max-width: 960px) {

  body {
    overflow-x: hidden;
  }

  .skills__container-cardInfo h3 {
    font-size: 0.85em;
  }

  .skills__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
  }

  .skills__container-card {
    width: 90%;
    margin: 24px 0;
  }

  .skills__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .skills__container-card:nth-child(5) {
    /*transform: scale(1);*/
    background: #56f5b8;
    margin: 24px;
    height: auto;
  }

  .skills__container-card:hover {
    transform: none;
  }
  .skills__container-pgbar {
    flex-direction: column;
    align-items: center;
  }  
}
