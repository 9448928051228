.work__section {
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #232429;
}

.work__container-card {
  background: linear-gradient(45deg, #7f9cc9 0%, #5b6777 100%);
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 300px;
  height: 450px;
  text-decoration: none;
  border-radius: 4px;
}

.work__container-card:nth-child(6) {
  transform: scale(1.05);
  background: #19ee23;
}

.work__container-card:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: #daa513;
}

.work__container-card {
  margin: 0 24px;
}

.work__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.work__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.work__heading {
  color: #1c2237;
  margin-bottom: 24px;
  font-size: 2.5em;
}

.work__container-cardInfo {
  display: flex;
  flex-direction: column;
  height: 450px;
  padding: 24px;
  align-items: center;
  color: rgb(34, 27, 27);
}

.work__container-cardInfo h3 {
  margin-bottom: 5px;
  font-size: 24px;
}

.work__container-cardInfo h4 {
  font-size: 40px;
}

.work__container-cardInfo p {
  font-size: 14px;
  margin-bottom: 24px;
}

.work__container-pgbar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work__container-features {
  margin: 16px 0 32px;
  list-style: none;
}

.work__container-features li {
  margin-bottom: 10px;
}

.work__container-features {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 24px 0;
  display: flex;
}

@media screen and (max-width: 960px) {
  .work__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .work__container-card {
    width: 80%;
    margin: 24px 0;
  }

  .work__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .work__container-card:nth-child(6) {
    transform: scale(1);
    background: #19ee23;
    margin: 24px;
    height: auto;
  }

  .work__container-card:hover {
    transform: none;
  }
  .work__container-pgbar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }  
}

@media screen and (max-width: 768px) {

  .work__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .work__container-card {
    width: 60%;
    height: 60%;
    margin: 24px 0;
  }

  .work__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .work__container-card:nth-child(6) {
    transform: scale(1);
    background: #19ee23;
    margin: 24px;
  }

  .work__container-card:hover {
    transform: none;
  }
  .work__container-pgbar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }  
}